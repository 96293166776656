@import url(https://fonts.googleapis.com/css?family=Work+Sans&display=swap);
body {
  margin: 0;
  font-family: "Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  background-color: #f5f4f0;
}
.MuiAppBar-root a,
a:visited {
  text-decoration: none;
  color: unset;
}

.testimonialDiv:before,
.testimonialDiv:after {
  position: relative;
  font-size: 25em;
  color: #df81662e;
  font-weight: 700;
}
.testimonialDiv:before {
  content: "\201c";
  margin-bottom: -300px;
  margin-top: -100px;
  top: 0;
  left: -22%;
  float: left;
}
.testimonialDiv:after {
  content: "\201d";
  bottom: 0;
  margin-top: -260px;
  margin-bottom: -200px;
  right: -22%;
  float: right;
}

.Carousel .Indicators .Indicator.Active {
  color: #df8166 !important;
}

.Carousel .Indicators .Indicator {
  color: #2b292d2b !important;
  font-size: 12px !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

