@import url("https://fonts.googleapis.com/css?family=Work+Sans&display=swap");

body {
  background-color: #f5f4f0;
}
.MuiAppBar-root a,
a:visited {
  text-decoration: none;
  color: unset;
}

.testimonialDiv:before,
.testimonialDiv:after {
  position: relative;
  font-size: 25em;
  color: #df81662e;
  font-weight: 700;
}
.testimonialDiv:before {
  content: "\201c";
  margin-bottom: -300px;
  margin-top: -100px;
  top: 0;
  left: -22%;
  float: left;
}
.testimonialDiv:after {
  content: "\201d";
  bottom: 0;
  margin-top: -260px;
  margin-bottom: -200px;
  right: -22%;
  float: right;
}

.Carousel .Indicators .Indicator.Active {
  color: #df8166 !important;
}

.Carousel .Indicators .Indicator {
  color: #2b292d2b !important;
  font-size: 12px !important;
}
